.btn {
  &:hover {
    opacity: 1;
  }
}

.btn-wide { min-width: 210px; }

.btn-link {
  padding-right: 0;
  padding-left: 0;

  font-weight: $font-weight-medium;
  color: $blue;

  &-white {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &-orange {
    color: $orange;

    &:hover {
      color: $blue;
    }
  }
}

.btn-arrow {
  &-before {
    &::before {
      margin-right: 10px;

      font-family: "Font Awesome 5 Pro";
      font-weight: $font-weight-bold;
      content: "\f061";

      transition: $transition-base;
    } 

    &-r {
      &::before {
        content: "\f060";
      }
    }

    &-u {
      &::after {
        content: "\f062";
      }
    }

    &-d {
      &::after {
        content: "\f063";
      }
    }

    &:hover {
      &::before { margin-right: 15px; }
    }
  }

  &-after {
    &::after {
      margin-left: 10px;
      
      font-family: "Font Awesome 5 Pro";
      font-weight: $font-weight-bold;
      content: "\f061";

      transition: $transition-base;
    }

    &-r {
      &::after {
        content: "\f060";
      }
    }

    &-u {
      &::after {
        content: "\f062";
      }
    }

    &-d {
      &::after {
        content: "\f063";
      }
    }

    &:hover {
      &::after { margin-left: 15px; }
    }
  }

  &-none {
    &::before,
    &::after {
      @extend .d-none;
    }
  }
}

.btn-white {
  background-color: $white;
  border-color: $white;
  color: $blue;
}

.btn-outline-white {
  background-color: transparent;
  border-color: $white;
  color: $white;

  &:hover {
    background-color: $white;
    color: $blue;
  }
}

.btn-inactive { opacity: 0.5; }

.btn-back {
  @extend .mb-3;
  @extend .btn-arrow-before;
  @extend .btn-arrow-before-r;
}

.btn-scroll {
  @extend .btn;
  @extend .btn-outline-primary;
  @extend .btn-arrow-after;
  @extend .btn-arrow-after-d;
}

.brands .link {
  @extend .btn;
  @extend .btn-link;
  @extend .btn-link-orange;
  @extend .btn-arrow-after;
}