.badge {
  padding: 5px 10px;

  font-weight: $font-weight-medium;
}

.badge-white {
  background-color: $white;
  border-color: $white;
  color: $blue;

  &:hover {
    background-color: $orange;
    border-color: $orange;
    color: $white;
  }
}