.footer_link,
.footer_logolink {
  .list {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-sm-row;
    @extend .flex-wrap;

    .list-item {
      @extend .mx-2;

      .link {
        color: $white;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}