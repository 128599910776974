/* ==========================================================================
   Typography
   ========================================================================== */

/* Menu
   ========================================================================== */
.tt-menu-toggler {
  font-size: $font-size-base;
  @extend .text-uppercase;
  color: $black;
}

.tt-menu-main {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  color: $black;
}
.tt-menu-main-hover {
  @extend .tt-menu-main;
  color: $blue;
}
.tt-menu-main-active {
  @extend .tt-menu-main;
  color: $orange;
}
.tt-menu-main-last {
  @extend .tt-menu-main;
  color: $orange;
}

.tt-menu-sub {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  color: $black;
}
.tt-menu-sub-hover {
  @extend .tt-menu-sub;
  color: $blue;
}
.tt-menu-sub-active {
  @extend .tt-menu-sub;
  color: $orange;
}


/* Banner
   ========================================================================== */
.tt-banner-title {
  font-size: $font-size-base * 2.5;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;
  color: $blue;
}

.tt-banner-subtitle {
  font-size: $font-size-base * 2.25;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;
  color: $orange;
}

.tt-banner-text {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  color: $blue;
}


/* Content
   ========================================================================== */
.tt-content-title {
  font-size: $font-size-base * 1.875;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;
  color: $blue;
}
.tt-content-title-white {
  @extend .tt-content-title;
  color: $white;
}

.tt-content-subtitle {
  font-size: $font-size-base * 1.875;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;
  color: $orange;
}
.tt-content-subtitle-blue {
  @extend .tt-content-subtitle;
  color: $blue;
}
.tt-content-subtitle-white {
  @extend .tt-content-subtitle;
  color: $white;
}

.tt-content-text {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  color: $black;
}
.tt-content-text-white {
  @extend .tt-content-text;
  color: $white;
}


/* Card
   ========================================================================== */
.tt-card-title {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;
  color: $blue;
}
.tt-card-title-white {
  @extend .tt-card-title;
  color: $white;
}

.tt-card-subtitle {
  font-size: $font-size-base * 0.875;
  font-weight: $font-weight-light;
  @extend .text-uppercase;
  color: $orange;
}
.tt-card-subtitle-white {
  @extend .tt-card-subtitle;
  color: $white;
}

.tt-card-text {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  color: $black;
}
.tt-card-text-white {
  @extend .tt-card-text;
  color: $white;
}


/* Highlight
   ========================================================================== */
.tt-highlight-title {
  @extend .tt-card-title;
  font-size: $font-size-base * 1.5;
}
.tt-highlight-title-white {
  @extend .tt-highlight-title;
  color: $white;
}

.tt-highlight-subtitle {
  @extend .tt-card-subtitle;
}
.tt-highlight-subtitle-white {
  @extend .tt-highlight-subtitle;
  color: $white;
}

.tt-highlight-text {
  @extend .tt-card-text;
}
.tt-highlight-text-white {
  @extend .tt-highlight-text;
  color: $white;
}


/* Employee
   ========================================================================== */
.tt-employee-title {
  font-size: $font-size-base * 1.25;
  font-weight: $font-weight-medium;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.875;
  }
}


/* Footer
   ========================================================================== */
.tt-footer-title {
  font-size: $font-size-base * 1.25;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.875;
  }
}
.tt-footer-subtitle {
  margin-bottom: 25px;

  font-size: $font-size-base * 1.125;
  font-weight: $font-weight-medium;
  @extend .text-uppercase;
}
.tt-footer-text {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  color: $white;
}


/* Blog
   ========================================================================== */
.tt-blog-text {
  font-size: $font-size-base;
  color: $blue;
}
.tt-blog-extra {
  font-size: $font-size-base * 0.875;
  font-weight: $font-weight-light;
  @extend .text-uppercase;
  color: $orange;
}