.navigator {
  position: relative;
  z-index: 100;

  @extend .mb-3;

  .container-holder {
    @extend .container-fluid;
    padding: 0;
  }

  .filter-items {
    position: relative;
    @extend .mx-0;

    border: 0;
    border-radius: 3px;

    @include make_row();

    @include media-breakpoint-up(sm) {
      border: 1px solid $blue;
    }
  }

  .filter-item {
    margin-bottom: -1px;
    @extend .px-0;

    border: 1px solid $blue;

    @include make-col-ready();
    @include make-col(12);

    .dropdown-toggle {
      @extend .w-100;
      padding: 13px 40px 13px 15px;
  
      background-color: rgba($white, 0.9);
      @extend .border-0;
  
      font-size: $font-size-base;
      font-weight: $font-weight-light;
      line-height: 24px;
      @extend .text-left;
      color: $blue;

      white-space: normal;
      cursor: pointer;

      &::after {
        position: absolute;
        top: 25px;
        right: 15px;
      }
    }

    .dropdown-menu {
      width: calc(100% + 2px);
      margin-top: 1px;
      @extend .p-0;
  
      background-color: transparent;
      border: 0;
      border-radius: 0px 0px 3px 3px;
  
      .dropdown-item {
        margin-top: -1px;
        margin-left: -1px;
        padding: 10px 15px;
  
        background-color: $white;
        border: 1px solid $blue;

        font-size: $font-size-base;
        font-weight: $font-weight-light;
        line-height: 24px;
        color: $blue;

        cursor: pointer;

        &:hover {
          background-color: #E9E9E9;
        }
      }
    }

    &.first {
      border-radius: 3px 3px 0 0;
      
      .dropdown-toggle {
        border-radius: 3px 3px 0 0;
      }
    }

    &.second {
      border-radius: 0 0 3px 3px;
      
      .dropdown-toggle {
        border-radius: 0 0 3px 3px;
      }
    }

    &.disabled {
      .dropdown-toggle {
        background-color: rgba(#c7c7c7, 0.9);
        cursor: not-allowed;
      }
    }

    @include media-breakpoint-up(sm) {
      flex: 1 1 auto;
      width: auto;
      margin-bottom: 0;

      border: 0;

      &.first {
        border-radius: 3px 0 0 3px;

        .dropdown-toggle {
          border-radius: 3px 0 0 3px;
        }
      }

      &.second {
        border-left: 1px solid $blue;
        border-radius: 0 3px 3px 0;

        .dropdown-toggle {
          padding: 13px 85px 13px 35px;
          border-radius: 0 3px 3px 0;

          &::after {
            right: 60px;
          }
        }

        .dropdown-menu {
          .dropdown-item {
            padding: 10px 15px 10px 35px;
          }
        }
      }
    }
  }

  .filter-btn {
    position: absolute;
    right: 50%;
    bottom: -20px;
    z-index: 10;

    margin-right: -15px;
    padding: 0;

    background-color: #FFFFFF;
    @extend .border-0;
    border-radius: 50%;

    font-size: 30px;
    line-height: 30px;
    color: $orange;

    transition: $transition-base;

    &:hover {
      color: $blue;
    }

    @include media-breakpoint-up(sm) {
      right: 0;
      bottom: 0;

      flex: 0 0 auto;
      
      margin-right: 0;
      padding: 10px 15px;

      background-color: transparent;
    }
  }

  &.search {
    .filter-item {
      .form-control {
        @extend .w-100;
        height: auto;
        padding: 13px 75px 13px 15px;

        font-size: 1rem;
        font-weight: 300;
        line-height: 24px;
        color: #183a6b;
        white-space: normal;
      }
    }

    .filter-btn {
      right: 0;
      bottom: 0;

      margin-right: 0;
      padding: 10px 15px;
      
      color: $orange;
    }
  }

  .eyecatcher & {
    position: absolute;
    bottom: 40px;
    left: 0;
  
    width: 100%;
    @extend .mb-0;
  
    .container-holder {
      max-width: 740px;
      padding: 0 40px;
    }
  }
}