.default.home {
	.section_one_extra {
		@extend .px-3;
		@extend .px-sm-5;

		padding-top: 40px;
		padding-bottom: 40px;

		@include media-breakpoint-up(md) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	.section_one {
		.container-default {
			@extend .px-0;
		}
	}

	.section_two {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;

		padding-top: 50px;
		padding-bottom: 70px;

		@include media-breakpoint-up(md) {
			padding-top: 90px;
		}

		.container-default {
			@extend .text-center;
		}

		.container-three-columns {
			margin-top: 40px;

			@include media-breakpoint-up(md) {
				margin-top: 87.5px;
			}

			.column {
				@extend .px-md-4;
			}

			.column.one,
			.column.two {
				@include media-breakpoint-up(md) {
					border-left: 1px solid #C7C7C7;
				}
			}
			.column.two,
			.column.three {
				@include media-breakpoint-up(md) {
					border-right: 1px solid #C7C7C7;
				}
			}
		}

		h2 {
			@extend .tt-content-subtitle-blue;
		}

		ul {
			@extend .list-link-custom;
		}
	}

	.section_three {
		@extend .px-0;
		@extend .px-md-5;

		@include media-breakpoint-up(md) {
			padding-top: 80px;
			padding-bottom: 80px;
		}

		.container-default {
			@extend .px-0;
		}
	}

	.section_four {
		@extend .px-0;
		@extend .px-md-5;

		@include media-breakpoint-up(md) {
			padding-top: 80px;
			padding-bottom: 80px;
		}

		.container-default {
			@extend .px-0;
		}
	}

	.section_five {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;

		padding-top: 50px;
		padding-bottom: 80px;

		.container-default {
			@extend .text-center;
		}

		.page_block {
			&.contentblock {
				&.grid {
					max-width: 750px;
					margin-top: 40px;
					@extend .mx-auto;

					@include media-breakpoint-up(md) {
						margin-top: 60px;
					}
				}
			}
		}

		h2 {
			@extend .tt-content-subtitle-blue;
		}
	}

	.section_six {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;

		padding-top: 70px;
		padding-bottom: 70px;
	}

	.section_seven {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;

		padding-top: 80px;
		padding-bottom: 60px;

		h2 {
			@extend .tt-content-subtitle-blue;
		}

		ul {
			@extend .list-normal-custom;
		}
	}

	.section_eight {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;

		padding-top: 70px;
		padding-bottom: 70px;

		.container-holder {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
		}
	}
}
