.default {
  &.product_overview {
    .section_one {
			@extend .px-3;
      @extend .px-sm-5;
      
      padding-top: 80px;
			padding-bottom: 80px;

			@extend .bg-tertiary;
		}
  }

  &.product_category,
  &.product_detail {
    .section_one {
			@extend .px-3;
      @extend .px-sm-5;
      
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .product_category_detail,
    .product_detail {
      @include make-row();
      
      .info {
        @include make-col-ready();
        @include make-col(12);
  
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
  
        @extend .mb-4;
        @extend .pr-lg-5;
  
        @extend .tt-blog-text;
  
        .extra {
          @extend .tt-blog-extra;
        }
      }
  
      .content {
        @include make-col-ready();
        @include make-col(12);
  
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
  
        @extend .mb-4;
        @extend .pl-lg-5;
      }

      .actions {
        @include make-col-ready();
        @include make-col(12);

        @extend .d-flex;
        @extend .justify-content-center;
        @extend .mb-4;
      }
    }

    .section_two {
			@extend .px-3;
      @extend .px-sm-5;

      padding-top: 80px;
      padding-bottom: 80px;
    }

    .section_three,
    .section_three_extra {
			@extend .px-3;
      @extend .px-sm-5;
      
      padding-top: 80px;
      padding-bottom: 80px;

      @extend .border-top;

      .container-default { max-width: 600px; }
    }
  }

  &.product_category {
    .section_four {
			@extend .px-3;
      @extend .px-sm-5;

      padding-top: 80px;
      padding-bottom: 80px;
     
      @extend .bg-tertiary;
    }

    .section_five {
			@extend .px-3;
      @extend .px-sm-5;

      padding-top: 80px;
      padding-bottom: 80px;

      .container-default {
        @extend .px-0;
      }
    }
  }

  &.product_detail {
    .section_four {
			@extend .px-3;
      @extend .px-sm-5;

      padding-top: 80px;
      padding-bottom: 80px;
      
      @extend .bg-tertiary;

      .container-default {
        @extend .px-0;
      }
    }
  }
}