.breadcrumb-list {
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .p-0;
  list-style: none;

  .breadcrumb-list-item {
    margin-right: 20px;
    
    font-size: $font-size-base * 0.875;
    font-weight: $font-weight-light;
    color: $blue;

    a {
      text-decoration: underline;
      color: $blue;

      &:hover {
        color: $orange;
      }
    }

    + .breadcrumb-list-item {
      &::before {
        margin-right: 5px;
        
        font-family: "Font Awesome 5 Pro";
        font-weight: $font-weight-bold;
        content: "\f061" !important;
      }
    }
  }
}