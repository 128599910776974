.page_block {
  &.employee_highlight {
    position: relative;

    max-width: 615px;
    width: 100%;
    @extend .mx-auto;

    .item-header {
      position: relative;
      z-index: 1;

      @extend .d-block;
      @extend .mx-auto;
      margin-bottom: -15px;
      padding: 0 25px;

      @extend .text-center;

      h2 {
        @extend .d-block;
        @extend .d-sm-inline;
        @extend .mb-0;
        @extend .tt-employee-title;
        padding: 0 25px;

        background-color: $orange;
      }
    }

    .item-body {
      @include make-row();
      @extend .align-items-center;
      @extend .justify-content-center;

      @extend .mx-0;
      padding: 40px 25px;
  
      border: 1px solid $white;
      border-radius: 6px;
  
      @extend .text-center;

      @include media-breakpoint-up(sm) {
				padding: 40px 50px;
      }
    }

    .item-text {
      @include make-col-ready();
      @include make-col(12);
      
      @extend .order-2;
      @extend .order-sm-1;
      @extend .px-0;
      @extend .pr-sm-3;
      @extend .pl-sm-0;

      font-weight: $font-weight-light;

      p {
        @extend .mb-0;
      }

      a {
        color: $white;

        &:hover {
          color: $blue;
        }
      }

      .item-btn {
        @extend .btn;
        @extend .btn-link;
        @extend .btn-link-white;
        @extend .btn-arrow-after;
        @extend .mt-4;

        &:hover {
          color: $blue;
        }
      }
    }

    .item-image {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
				@include make-col(6);
      }
      
      @extend .order-1;
      @extend .order-sm-2;

      @extend .mb-5;
      @extend .mb-sm-0;
      @extend .px-0;
      @extend .pr-sm-0;
      @extend .pl-sm-3;

      img {
        @extend .rounded-circle;
        max-width: 100%;
      }
    }

    .item-footer {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;

      padding-top: 100px;
      padding-bottom: 70px;

      @include media-breakpoint-up(sm) {
        padding-top: 30px;
        padding-bottom: 115px;
      }
    }
  }
}