/* body */
body {
	@include media-breakpoint-up(xl) {
		transition: $transition-base;

		&:not(.home) {
			padding-top: 140px;
		}
	}

	&.sticky {
		@include media-breakpoint-up(xl) {
		}
	}
}

/* containers */
.container-fluid {
	max-width: 2000px;
}

/* main */
.main {
	h1 {
		@extend .tt-content-title;
	}

	h2 {
		@extend .tt-content-subtitle;
	}

	h3 {
		@extend .tt-content-title;
		font-size: $font-size-base * 1.125;
	}

	p {
		@extend .tt-content-text;
	}

	.caption {
		.btn {
			@extend .btn-link;
			@extend .btn-arrow-after;
		}
	}

	ul,
	ol {
		li {
			font-size: $font-size-base;
			color: $blue;
		}

		a {
			font-weight: $font-weight-light;
			color: $orange;
		}
	}

	.bg-secondary {
		h1 {
			@extend .mb-4;
			@extend .tt-content-subtitle-white;
		}

		h2 {
			@extend .tt-content-subtitle-white;
		}

		h3 {
			@extend .tt-content-title-white;
			font-size: $font-size-base * 1.125;
		}

		p {
			@extend .tt-content-text-white;
		}

		a {
			&:not([class]),
			&[class=""] {
				text-decoration: underline;
				color: $white;

				&:hover {
					color: $orange;
				}
			}
		}

		.caption {
			.btn {
				@extend .btn-link-white;
			}
		}

		ul,
		ol {
			li {
				color: $white;
			}

			a {
				color: $white;
			}
		}
	}
}

.list-normal-custom {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding-left: 25px;

		font-weight: $font-weight-bold;

		&::before {
			position: absolute;
			left: 0;

			font-family: "Font Awesome 5 Pro";
			content: "\f00c";
		}
	}
}

.list-link-custom {
	padding: 0;
	list-style: none;

	li {
		padding-left: 0;

		&::before {
			@extend .d-none;
		}

		a {
			position: relative;
			padding-left: 25px;

			text-decoration: none;

			&::before {
				position: absolute;
				left: 0;
		
				font-family: "Font Awesome 5 Pro";
				font-weight: $font-weight-bold;
				content: "\f061";
			}
		}
	}
	
	&-pdf {
		li {
			a {
				&::before {
					content: "\f1c1";
				}
			}
		}
	}
}

.list-icons {
	@extend .d-flex;
	@extend .flex-wrap;
	@extend .justify-content-center;
	@extend .mt-4;
	@extend .p-0;

	list-style: none;

	li {
		a {
			display: block;
			width: 35px;
			@extend .mx-1;

			line-height: 35px;

			background-color: $white;
			@extend .rounded-circle;
			color: $blue !important;

			&:hover {
				background-color: $blue;
				color: $white !important;
			}
		}
	}
}