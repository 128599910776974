.gallery {
  .gallery-holder {
    @include make-row();

    .highlight {
      @include make-col-ready();
      @include make-col(12);

      transition: $transition-base;

      &:hover {
        transform: scale(1.025);
      }
    }

    .item {
      @include make-col-ready();
      @include make-col(6);

      @extend .mt-4;

      transition: $transition-base;

      &:hover {
        transform: scale(1.025);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }

    a {
      @extend .d-block;
    }

    .img-fluid {
      width: 100%;
    }
  }
}