.page_block {
  &.contentblock {
    &.grid {
      @extend .mb-sm-n5;

      .item {
        @extend .mb-sm-5;

        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }
    }

    &.card-columns {
      @include media-breakpoint-up(xl) {
        column-count: 3;
      }
    }
  }
}