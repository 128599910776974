.navigator-search {
  position: relative;
  z-index: 100;

  @extend .mb-3;

  .filter-items {
    position: relative;
    @extend .mx-0;

    @extend .border-0;

    @include make_row();
  }

  .filter-item {
    margin-bottom: -1px;
    @extend .px-0;

    border: 1px solid $blue;
    border-radius: 3px;

    @include make-col-ready();
    @include make-col(12);

    .form-control {
      @extend .w-100;
      height: auto;
      padding: 13px 75px 13px 15px;
      
      background-color: rgba($white, 0.9);
      border-radius: 3px;
      @extend .border-0;

      font-size: 1rem;
      font-weight: 300;
      line-height: 24px;
      color: #183a6b;
      white-space: normal;
    }
  }

  .filter-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;

    margin-right: 0;
    padding: 10px 15px;
    
    @extend .bg-transparent;
    @extend .border-0;

    font-size: 30px;
    line-height: 30px;
    color: $orange;

    transition: $transition-base;

    &:hover {
      color: $blue;
    }

    @include media-breakpoint-up(sm) {
      right: 0;
      bottom: 0;

      flex: 0 0 auto;
      
      margin-right: 0;
      padding: 10px 15px;
    }
  }

  .eyecatcher & {
    @extend .mt-4;
    @extend .mt-sm-3;
    @extend .mb-0;
    

    .container-holder {
      padding: 0;
    }
  }
}