.footer {
  padding-top: 135px;
  padding-bottom: 60px;
  @extend .tt-footer-text;

  h1 {
    @extend .tt-footer-title;
    @extend .text-center;
    @extend .text-sm-left;
  }

  h5 {
    @extend .tt-footer-subtitle;
  }

  .footer-btn {
    margin-top: 100px;
    @extend .mb-2;
    
    @extend .btn;
    @extend .btn-wide;
    @extend .btn-white;

    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }

  .footer-link {
    @extend .btn;
    @extend .btn-wide;
    @extend .btn-link;
    @extend .btn-link-white;
    @extend .btn-arrow-after;
  }

  .footer-top {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;
    @extend .pb-5;

    color: $white;

    .container-default {
      &:nth-child(1) {
        .container-holder {
          @extend .d-flex;
          @extend .flex-column;
          @extend .align-items-center;

          >:last-child {
            margin-bottom: 70px;

            @include media-breakpoint-up(sm) {
              margin-bottom: 115px;
            }
          }
        }
      }
    }

    .container-four-columns {
      margin-top: 40px;
      padding-right: 30px;
      padding-left: 30px;
      @extend .px-sm-3;

      .column {
        @extend .px-3;
        @extend .p-sm-4;

        @extend .py-5;
        @extend .py-sm-5;
        @extend .py-lg-3;

        @extend .text-center;
        @extend .text-sm-left;
			}

			.column.one,
      .column.two,
      .column.three,
      .column.four {
        border-bottom: 1px solid #C7C7C7;

        @include media-breakpoint-up(sm) {
          border-left: 1px solid #C7C7C7;
          border-bottom: 0;
        }
      }
      .column.two {
        @include media-breakpoint-up(sm) {
          border-right: 1px solid #C7C7C7;
        }
        @include media-breakpoint-up(lg) {
          border-right: 0;
        }
      }

			.column.four {
        border-bottom: 0;

        @include media-breakpoint-up(sm) {
					border-right: 1px solid #C7C7C7;
				}
			}
    }
  }

  .footer-bottom {
		padding-right: 25px;
		padding-left: 25px;
		@extend .px-sm-5;
    @extend .py-3;

    @extend .text-center;

    .column.navbar {
      @extend .justify-content-center;
      @extend .p-0;
    }
  }
}
