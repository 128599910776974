.page_block {
  &.slider {
    .item {
      padding: 6px;
    }
  }

  &.carousel {
    .eyecatcher & {
      @extend .py-0;
    }
  }

  &.form {
    @extend .mb-5;
    @extend .tt-content-text;

    .bg-secondary & {
      @extend .tt-content-text-white;

      .btn {
        @extend .btn-outline-white;
      }
    }
  }

  &.search_results {
    &.grid {
      .search_alert {
        @include make-col-ready();
        @include make-col(12);
      }

      .search_term {
        @include make-col-ready();
        @include make-col(12);
      }

      .item {
        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }
    }
  }

  &.blog_overview {
    &.grid {
      .item {
				@include media-breakpoint-up(sm) {
          @include make-col(12);
        }
        @include media-breakpoint-up(md) {
          @include make-col(12);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(6);
        }
      }
    }
  }

  &.cases_overview,
  &.cases_category_overview,
  &.product_overview,
  &.product_category_overview,
  &.services_overview,
  &.services_category_overview {
    &.grid {
      .item {
        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }
    }
  }
  &.product_category_overview {
    .card {
      .card-image {
        @extend .d-none;
      }
    }
  }

  &.cases_category_call_to_action,
  &.cases_call_to_action,
  &.product_category_call_to_action,
  &.product_call_to_action,
  &.services_category_call_to_action,
  &.services_call_to_action {
    @extend .mt-5;

    .call-to-action-btn {
      @extend .btn;
      @extend .mr-3;
      @extend .mb-1;

      &:nth-child(1) {
        @extend .btn-wide;
        @extend .btn-outline-white;

        &.btn-active {
          @extend .btn-white;
        }
      }

      &:nth-child(2) {
        @extend .btn-wide;
        @extend .btn-outline-white;

        &.btn-active {
          @extend .btn-white;
        }
      }
    }
  }

  &.cases_category_info,
  &.cases_info,
  &.product_category_info,
  &.product_info,
  &.services_category_info,
  &.services_info {
    ul {
      @extend .list-normal-custom;
    }
  }

  &.cases_pdf,
  &.product_pdf,
  &.services_pdf {
    .pdf-list {
      @extend .list-link-custom;
      @extend .list-link-custom-pdf;
    }
  }
}
