.card {
  @extend .h-100;
  border: 0;
  @extend .rounded-0;

  box-shadow: 0px 3px 6px 0px rgba($black, 0.16);

  .card-image {
    min-height: 1px;
    
    .card-image-link {
      @extend .d-block;
    }
  }

  .card-img-overlay {
    background-color: rgba($black, 0.8);

    .card-caption {
      @extend .d-flex;
      @extend .flex-column;
      @extend .h-100;
      
      @extend .text-white;
    }

    .card-title-link {
      order: 2;

      &:hover {
        .card-title {
          color: $orange;
        }
      }
    }

    .card-title {
      order: 2;
      @extend .tt-card-title-white;
    }

    .card-subtitle {
      order: 1;
      @extend .tt-card-subtitle-white;
    }

    .card-text {
      order: 3;
      @extend .tt-card-text-white;

      p {
        @extend .tt-card-text-white;
      }
    }

    .card-buttons {
      order: 4;
      @extend .mt-auto;

      .card-btn {
        @extend .btn;
        @extend .mr-3;
        @extend .mb-1;

        &:nth-child(1) {
          @extend .btn-outline-white;
        }
        &:nth-child(2) {
          @extend .btn-link;
          @extend .btn-link-white;
        }
      }
    }
  }

  .card-body {
    padding: 30px;

    .card-caption {
      @extend .d-flex;
      @extend .flex-column;
      @extend .h-100;
    }

    .card-title-link {
      order: 2;

      &:hover {
        .card-title {
          color: $orange;
        }
      }
    }

    .card-title {
      order: 2;
      @extend .tt-card-title;
    }
      
    .card-subtitle {
      order: 1;
      @extend .tt-card-subtitle;
    }

    .card-text {
      order: 3;
      @extend .tt-card-text;
    }

    .card-buttons {
      order: 4;
      @extend .mt-auto;
      
      .card-btn {
        @extend .btn;
        @extend .mr-3;
        @extend .mb-1;

        &:nth-child(1) {
          @extend .btn-link;
          @extend .btn-arrow-after;
        }
        &:nth-child(2) {
          @extend .btn-link;
          @extend .btn-arrow-after;
        }
      }
    }
  }


  /* card - wide */
  &.wide {
    @extend .flex-row;
    @extend .flex-wrap;

    .card-image {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
      
      @extend .p-0;

      .card-img-top {
        @extend .rounded-0;
      }
    }

    .card-body {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }
    }

    /* card - content-left/right */
    &.content-left,
    &.content-right {
      @extend .border-0;
      @extend .rounded-0;
    }
    &.content-left {
      .card-image {
        @include media-breakpoint-up(md) {
          order: 2;
        }
      }
      
      .card-body {
        @include media-breakpoint-up(md) {
          order: 1;
        }
      }
    }
  }


  /* card - default */
  &.default {
    .cards-overview & {
      @extend .bg-transparent;
      @extend .border-0;

      @extend .shadow-none;

      .card-body {
        @extend .px-0;

        .card-title {
          @extend .tt-card-title;
          font-size: $font-size-base * 1.625;

          order: 2;
        }

        .card-subtitle {
          @extend .tt-card-subtitle;
          font-size: $font-size-base;
        }
      }
    }

    .cards-overview.bg-secondary & {
      .card-body {
        .card-title {
          @extend .tt-card-title-white;
          font-size: $font-size-base * 1.625;
        }

        .card-subtitle {
          @extend .tt-card-subtitle-white;
          font-size: $font-size-base;
        }
      }
    }
  }
  

  /* card - icon */
  &.icon {
    @extend .justify-content-center;
    @extend .align-items-center;
    padding-top: 14px;
    padding-bottom: 14px;
    @extend .px-4;

    border: 1px solid $orange;
    @extend .rounded;

    @extend .text-center;

    transition: $transition-base;

    .card-image {
      @extend .mx-auto;
      @extend .mb-3;
      max-width: 100px;
    }

    .card-body {
      @extend .p-0;

      .card-title { color: $orange; }

      .card-buttons {
        .card-btn {
          @extend .btn-link-orange;
        }
      }
    }

    /* a.card - icon */
    @at-root {
      a#{&} {
        &:hover {
          border-color: $blue;

          transform: scale(1.025);
        }
      }
    }
  }


  /* card - logo */
  &.logo {
    @extend .bg-transparent;
    @extend .border-0;

    transition: $transition-base;
    @extend .shadow-none;

    .card-image {
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
      @extend .w-100;
      @extend .h-100;

      img { max-width: 200px; }
    }

    .card-body {
      @extend .d-none;
    }

    &:hover {
      transform: scale(1.025);
    }
  }


  /* highlight - card */
  .highlight & {
    @extend .bg-transparent;
    box-shadow: none;

    .card-image {
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }

    .card-body {
      padding: 55px 40px;

      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(md) {
        @include make-col(6);

        padding: 55px 60px;
      }
      @include media-breakpoint-up(xl) {
        padding: 90px 120px;
      }

      .card-title {
        @extend .mb-4;
        @extend .tt-highlight-title;
      }

      .card-subtitle {
        @extend .tt-highlight-subtitle;
      }

      .card-buttons {
        .card-btn {
          @extend .btn-wide;

          &:nth-child(1) {
            padding: $btn-padding-y $btn-padding-x;
            @extend .btn-outline-primary;
            @extend .btn-arrow-none;
          }
          &:nth-child(2) {
            @extend .btn-link-orange;
            @extend .btn-arrow-after;
          }
        }
      }
    }

    .bg-secondary & {
      .card-body {
        .card-title { color: $white; }
        .card-subtitle { color: $white; }
        .card-text { color: $white; }

        .card-buttons {
          .card-btn {
            &:nth-child(1) {
              @extend .btn-outline-white;
            }
            &:nth-child(2) {
              @extend .btn-link-white;
            }
          }
        }
      }
    }
  }


  /* cards-overview - card */
  .cards-overview & {
    &:not(.default) {
      transition: $transition-base;

      .card-subtitle {
        position: relative;
        padding-right: 30px;

        &::after {
          position: absolute;
          right: 0;

          font-family: "Font Awesome 5 Pro";
          font-weight: $font-weight-bold;
          color: $blue;

          content: "\f061";
        }
      }

      .card-text {
        @extend .d-none;
      }

      .card-buttons {
        .card-btn {
          @extend .mr-3;
          @extend .px-0;
        }
      }

      &:hover {
        transform: scale(1.025);
      }
    }
  }
}