.default {
	&.contact {
		.section_one {
      padding-right: 25px;
      padding-left: 25px;
      @extend .px-sm-5;

      padding-top: 80px;
      padding-bottom: 80px;
    }
    
    .section_two {
      .container-fluid {
        @extend .px-0;
      }

      p {
        @extend .mb-0;
      }

      iframe {
        @extend .d-block;
      }
    }
	}
}
