// Variables
//
// Color system

$black: 						#000000;
$blue: 							#183A6B;
$light-gray: 				#F1F2F3;
$orange: 						#CF611B;
$white:							#FFFFFF;

$theme-colors: (
	"primary": 					$orange,
	"secondary":				$blue,
	"danger":     			$orange,
	"tertiary": 				$light-gray,
);

// Body
//
// Settings for the `<body>` element.

$body-color: $black !default;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base: 'Noto Sans KR', sans-serif;
$headings-font-family: $font-family-base;
$input-btn-font-family: $font-family-base;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-medium: 500 !default;



// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: $font-weight-medium;
// $btn-border-radius: 			0 !default;

// Forms
// $input-border-radius: 		0;

// 3W MEDIA STYLING

// theme styling
$theme-color-primary: map-get($theme-colors, 'primary');
$theme-color-primary-dark: darken($theme-color-primary, 10%);

$theme-color-secondary: map-get($theme-colors, 'secondary');
$theme-color-secondary-dark: darken($theme-color-secondary, 10%);

$theme-color-tertiary: map-get($theme-colors, 'tertiary');
$theme-color-tertiary-dark: darken($theme-color-tertiary, 10%);

// Eyecatcher
$eyecatcher-height-big: 70vh;
$eyecatcher-height-small: 35vh;

// links
$link-hover-decoration: none !default;
