.carousel {
  /* carousel - normal */
  .carousel-item {
    @include carousel-height();
    
    .carousel-holder {
      @include carousel-height();
    }

    .carousel-caption {
      right: 0;
      bottom: 0;
      left: 0;

      padding: 20px;

      @extend .text-left;

      background-color: rgba($white, 0.7);
    }

    .carousel-title {
      @extend .tt-banner-title;
      @extend .mb-0;
    }

    .carousel-subtitle {
      @extend .tt-banner-subtitle;
      @extend .mb-2;
    }

    .carousel-text {
      @extend .tt-banner-text;
    }

    .carousel-btn {
      @extend .btn;
      @extend .btn-outline-primary;
    }
  }

  /* carousel - background */
  .carousel-item-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .carousel-holder {
      @extend .d-flex;
      @extend .align-items-end;

      .container {
        max-width: 740px;
        padding: 0 40px;
      }
    }

    .carousel-caption {
      position: relative;
      right: unset;
      bottom: unset;
      left: unset;

      max-width: 500px;
      padding: 325px 0 225px 0;

      background-color: transparent;

      @include media-breakpoint-up(sm) {
        padding-bottom: 170px;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    @extend .d-none;
  }

  .carousel-indicators {
    @extend .d-none;
  }
}