.default {
  &.search {
    .section_one {
      padding-right: 25px;
      padding-left: 25px;
      @extend .px-sm-5;

      padding-top: 80px;
      padding-bottom: 80px;
      
			@extend .bg-tertiary;
    }
  }
}