.page_block {
  &.grid {
    @include make-row();
    
    @extend .mb-n3;
    @extend .py-3;

    .item {
      @include make-col-ready();
      @include make-col(12);

      @extend .mb-3;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }
  }

  &.card-columns {
    column-count: 1;
    column-gap: 30px;

    @extend .py-3;

    .card {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }
    @include media-breakpoint-up(md) {
      column-count: 2;
    }
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }
    @include media-breakpoint-up(xl) {
      column-count: 4;
    }
  }

  &.inspiration {
    @include make-row();

    @extend .mb-n3;
    @extend .py-3;
    
    .item {
      @include make-col-ready();
      @include make-col(12);

      min-height: 200px;
      @extend .mb-3;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
    }
  }

  &.carousel {
    @extend .py-3;
  }

  &.slider {
    @extend .py-3;
    
    .item {
      min-height: 0;
    }
  }
}