.header {
  @extend .d-flex;
  @extend .flex-column;

  @extend .d-xl-block;

  position: relative;
  z-index: 999;

  background-color: rgba(255,255,255,0.7);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  transition: $transition-base;

  &:hover {
    background-color: rgba(255,255,255,1);
  }

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .header-socket {
    display: none;

    @extend .order-1;

    @include media-breakpoint-up(xl) {
      display: block;
    }
    
    .container-navbar {
      @extend .px-0;
    }
      
    .column.navbar {
      @extend .navbar-expand;
      @extend .justify-content-xl-end;

      padding: 10px 40px;
      transition: $transition-base;

      .navbar-nav {
        @extend .flex-wrap;

        @extend .ml-xl-auto;
  
        .nav-item {
          margin-right: 30px;

          @include media-breakpoint-up(xl) {
            margin-right: 0;
          }
          
          .nav-link {
            padding: 10px 0;
            @extend .tt-menu-sub;
  
            &:hover {
              @extend .tt-menu-sub-hover;
            }

            @include media-breakpoint-up(xl) {
              padding: 10px 15px;
            }
          }

          &.active {
            .nav-link {
              @extend .tt-menu-sub-active;
            }
          }
        }

        .dropdown-menu {
          right: auto;
          left: 0;
          @extend .p-0;

          @extend .border-0;
          border-radius: 3px;

          @extend .shadow;

          @include media-breakpoint-up(xl) {
            right: 0;
            left: auto;
          }

          .nav-item {
            .nav-link {
              padding: 10px 15px;

              &:hover {
                background-color: #E9E9E9;
              }
            }
          }
        }
      }

      .language {
        .nav-item {
          .nav-link {
            text-transform: uppercase;

            &::before {
              font-family: "Font Awesome 5 Pro";
              font-weight: 400;
              content: "\f0ac";
            }
          }

          .dropdown-menu {
            .dropdown-item {
              padding: 10px 15px;

              @extend .tt-menu-sub;
  
              &:hover {
                background-color: #E9E9E9;
                @extend .tt-menu-sub-hover;
              }
            }
          }
        }
      }
    }

    .socials {
      @extend .flex-wrap;
    }
  }

  .header-main {
    @extend .order-0;

    .container-navbar {
      @extend .px-0;
    }

    .column.navbar {
      position: initial;
      @extend .navbar-expand-xl;
      @extend .navbar-light;

      min-height: 125px;
      padding: 20px 40px;
      transition: $transition-base;

      @include media-breakpoint-up(xl) {
        min-height: 0;
      }

      .navbar-toggler {
        @extend .ml-auto;
        @extend .border-0;

        @extend .tt-menu-toggler;
        line-height: 21px;

        .navbar-toggler-icon {
          width: auto;
          height: auto;
          
          background-image: none;
        }
      }

      .navbar-collapse {
        @extend .pt-sm-5;
        @extend .pt-xl-0;
      }

      .navbar-nav { 
        @extend .ml-auto;
        @extend .pt-3;
        @extend .pt-sm-0;
  
        > .nav-item {
          > .nav-link {
            @extend .px-xl-3;
            @extend .tt-menu-main;
  
            &:hover {
              @extend .tt-menu-main-hover;
            }
          }

          .dropdown-menu {
            &.show {
              z-index: 10;

              @extend .d-flex;
              @extend .flex-wrap;
              @extend .w-100;
              @extend .mt-0;

              background-color: rgba(255,255,255,1);
              @extend .border-0;
              border-radius: 0;

              > li,
              .dropdown {
                max-width: 300px;
                width: 100%;
                margin-bottom: 20px;

                > .nav-link {
                  padding: 5px 15px 5px 0px;

                  font-size: $font-size-base * 0.875;
                  font-weight: $font-weight-medium;
                  @extend .text-uppercase;
                  color: $black;

                  &[href^="javascript:void(0);"] {
                    pointer-events: none;
                  }

                  &:hover,
                  &:focus {
                    @extend .bg-transparent;
                    outline: none;
                    color: $orange;
                  }
                }

                > .dropdown-menu {
                  @extend .m-0;
                  @extend .p-0;

                  @extend .bg-transparent;
                  @extend .border-0;

                  .nav-item {
                    .nav-link {
                      padding: 2.5px 15px 2.5px 25px;

                      font-size: $font-size-base * 0.875;
                      font-weight: $font-weight-light;
                      color: $blue;

                      &::before {
                        position: absolute;
                        left: 0;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: $font-weight-bold;
                        content: "\f061";
                      }

                      &:hover,
                      &:focus {
                        @extend .bg-transparent;
                        outline: none;
                        color: $orange;
                      }
                    }

                    &.active {
                      .nav-link {
                        color: $orange;
                      }
                    }
                  }
                }

                &:last-of-type { margin-bottom: 0; }
              }

              .dropdown-menu {
                position: relative;
                top: 0;
                left: 0;

                @extend .d-block;
              }

              &::before {
                @extend .d-none;
                @extend .d-xl-block;

                position: absolute;
                top: 0;
                right: 55px;
                left: 30vw;

                border-top: 1px solid $blue;
                content: "";
              }
            }
          }

          &.dropdown {
            position: inherit;
          }
  
          &.active {
            .nav-link {
              @extend .tt-menu-main-active;
            }
          }
        }
      }
    }

    .logo {
      max-width: 75px;
      transition: $transition-base;

      a {
        @extend .d-block;

        img {
          @extend .w-100;
        }
      }

      &:nth-child(2) {
        @extend .d-none;
        @extend .d-md-block;
      }
      &:nth-child(3) {
        @extend .d-none;
        @extend .d-md-block;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .header-main {
      .logo {
        position: absolute;
        top: 20px;
        left: 50px;

        max-width: 155px;

        &:nth-child(2) {
          top: 55px;
          left: 255px;
        }
        &:nth-child(3) {
          top: 40px;
          left: 420px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .header-main {
      .column.navbar {
        position: relative;

        .navbar-nav { 
          > .nav-item {
            .dropdown-menu {
              &.show {
                padding: 20px 35px 35px 30vw;
                box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

                .dropdown { margin-bottom: 40px; }
              }
            }

            &.show {
              > .nav-link {
                position: relative;
                z-index: 20;
  
                &::before {
                  position: absolute;
                  bottom: -22px;
                  left: 50%;
  
                  width: 27px;
                  height: 15px;
                  margin-left: -13.5px;
  
                  background-image: url('~project/public/images/triangle-up.svg');
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-color: $white;
                  content: '';
  
                  transition: $transition-base;
                }
              }
            }
          }
        }
      }

      .logo {
        top: inherit;
        bottom: 15px;

        &:nth-child(2) {
          top: inherit;
          bottom: 30px;
        }
        &:nth-child(3) {
          top: inherit;
          bottom: 25px;
        }
      }
    }
  }

  &.sticky {
    @include media-breakpoint-up(xl) {
      position: fixed;
      min-height: 0;

      background-color: rgba($white, 0.9);

      .header-socket { 
        .column.navbar {
          padding: 5px 40px;
        }
      }
  
      .header-main { 
        .column.navbar {
          padding: 5px 40px;

          .navbar-nav {
            > .nav-item {
              &.show {
                > .nav-link {
                  &::before {
                    bottom: -7px;
                  }
                }
              }
            }
          }
        }
      }

      &:hover {
        background-color: rgba(255,255,255,1);
      }
    }

    .logo {
      @include media-breakpoint-up(xl) {
        max-width: 100px;

        &:nth-child(2) {
          top: inherit;
          bottom: 20px;
          left: 175px;
        }
        &:nth-child(3) {
          top: inherit;
          bottom: 15px;
          left: 285px;
        }
      }
    }
  }
}
