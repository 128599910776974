.default {
	&.blog_post_overview {
		.section_one {
			@extend .px-3;
			@extend .px-sm-5;

			padding-top: 80px;
			padding-bottom: 80px;

			@extend .bg-tertiary;
		}

		.blog-category-filter {
			@extend .mb-5;

			.list {
				@extend .d-flex;
				@extend .flex-wrap;
				@extend .justify-content-center;

				.list-item {
					@extend .mr-2;

					.list-link {
						@extend .badge;
						@extend .badge-white;
						@extend .shadow-sm;
					}
				}
			}
		}
	}

	&.blog_category_detail,
	&.blog_post_detail {
		.section_one {
			@extend .px-3;
			@extend .px-sm-5;

			padding-top: 80px;
			padding-bottom: 80px;
		}
	}
	&.blog_category_detail {
		@extend .bg-tertiary;
	}

	.blog_detail {
		@include make-row();

		.info {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pr-lg-5;

			@extend .tt-blog-text;

			.extra {
				@extend .tt-blog-extra;
			}
		}

		.content {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pl-lg-5;
		}
	}
}
